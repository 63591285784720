import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import mediaqueries from "../../styles/media"
import MenuItem from "./MenuItem"

interface Props {
  location: any
  theme?: any
}

interface Item {
  name: string
  type: "internal" | "external"
  link: string
}

const siteQuery = graphql`
  query navQuery {
    mdx(frontmatter: { title: { eq: "Navigation" } }) {
      id
      frontmatter {
        leftMenu {
          name
          link
          type
        }
        rightMenu {
          name
          link
          type
        }
        logo {
          id
          publicURL
        }
      }
    }
  }
`

const checkActive = (link: string, pathname: string) => {
  if (pathname === "/" && link === "/") {
    return true
  }

  if (pathname === link) {
    return true
  }

  return false
}

const Navigation: React.FC<Props> = ({ location }) => {
  const data = useStaticQuery(siteQuery)
  const { leftMenu, rightMenu, logo } = data.mdx.frontmatter

  return (
    <NavigationHolder>
      <Logo>
        <Link to="/">
          <img src={logo.publicURL} width="105px" height="50px" />
        </Link>
      </Logo>
      <LeftMenu>
        {leftMenu.map((item: Item) => (
          <MenuItem
            key={item.name}
            text={item.name}
            link={item.link}
            type={item.type}
            active={checkActive(item.link, location.pathname)}
          />
        ))}
      </LeftMenu>
      <RightMenu>
        {rightMenu.map((item: Item) => (
          <MenuItem
            key={item.name}
            text={item.name}
            link={item.link}
            type={item.type}
            active={checkActive(item.link, location.pathname)}
          />
        ))}
      </RightMenu>
    </NavigationHolder>
  )
}

const NavigationHolder = styled.nav`
  max-width: 1440px;
  width: 100%;
  height: 55px;
  padding: 10px;
  background: ${(p: any) => p.theme.colors.background};
  border-radius: ${(p: any) => p.theme.radius.normal};
  display: flex;
  filter: ${(p: any) => p.theme.shadow.box};
  margin-bottom: 100px;
  z-index: 9999999999999;
  position: relative;
  font-family: ${(p: any) => p.theme.fonts.exo};

  ${mediaqueries.desktop`
  display: none;
`};
`

const Logo = styled.div`
  width: 110px;
  margin-top: -5px;
  margin-left: 20px;
`

const LeftMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 55%;
  height: 100%;
  margin: auto;
  list-style: none;
`

const RightMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 35%;
  height: 100%;
  margin: auto;
  list-style: none;

  li:first-of-type {
    border-right: 1px solid ${(p: any) => p.theme.colors.secondary};
    padding-right: 40px;
  }
`

export default Navigation
