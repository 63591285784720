import React from "react"

import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import mediaqueries from "../../styles/media"

import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Anchor from "../anchor"
import Blockquote from "../blockquote"
import Headings from "../headings"
import HorizontalRule from "../horizontalRule"
import Lists from "../lists"
import Paragraph from "../paragraph"
import Tables from "../tables"
import { ImageZoom } from "../image"

const components = {
  img: ImageZoom,
  a: Anchor,
  blockquote: Blockquote,
  h1: Headings.h2, // h1 reserved article title
  h2: Headings.h2,
  h3: Headings.h3,
  h4: Headings.h4,
  h5: Headings.h5,
  h6: Headings.h6,
  hr: HorizontalRule,
  ul: Lists.ul,
  ol: Lists.ol,
  p: Paragraph,
  table: Tables.Table,
  thead: Tables.Head,
  th: Tables.HeadCell,
  td: Tables.Cell,
}

interface Props {
  content: string
  children?: any
}

const MDX: React.FC<Props> = ({ content, children, ...props }) => {
  return (
    <MDXProvider>
      <MDXBody>
        <MDXRenderer components={components} {...props}>
          {content}
        </MDXRenderer>
        {children}
      </MDXBody>
    </MDXProvider>
  )
}

export default MDX

const IMAGE_WIDTHS = {
  regular: "680px",
  large: "1004px",
  full: "100vw",
}

const ARTICLE_WIDTH = css`
  width: 100%;
  max-width: 1440px;

  ${mediaqueries.desktop`
      max-width: 507px;
    `}

  ${mediaqueries.tablet`
      max-width: 486px;
    `};

  ${mediaqueries.phablet`
      padding: 0 20px;
    `};
`

const HeadingsCSS = (props: any) => css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 auto;
  }

  h1,
  h1 *,
  h2,
  h2 * {
    margin: 25px auto 18px;

    ${mediaqueries.tablet`
        margin: 30px auto 18px;
      `};
  }

  h3,
  h3 * {
    margin: 20px auto 10px;
  }

  h1 {
    font-family: ${props.theme.fonts.sansSerif};
    font-size: 36px;
    line-height: 1.15;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 122.19%;
    letter-spacing: 0.05em;
    color: ${props.theme.colors.white};
    max-width: 630px;
    margin: 0px;

    ${mediaqueries.desktop`
        font-size: 24px;
        max-width: 80vw;
      `};
  }

  h1 strong {
    font-family: ${props.theme.fonts.exo};
    font-weight: 600;
  }

  h4 {
    color: ${props.theme.colors.primary};
    font-family: ${props.theme.fonts.sansSerif};
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 197.18%;
    /* or 37px */
    letter-spacing: 0.015em;
  }

  h4 strong {
    color: ${props.theme.colors.secondary};
  }

  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
`

const ImageCSS = css`
  .gatsby-resp-image-background-image {
    display: none !important;
  }

  img {
    display: inline-block;
    position: relative;
    max-width: 100%;
    height: auto;
    z-index: 0;
    margin: 15px auto 50px;
    border-radius: 5px;

    ${mediaqueries.tablet`
        margin: 10px auto 45px;
      `};
  }

  div.Image__Small {
    display: inline-block;
    position: relative;
    max-width: 100%;
    height: auto;
    z-index: 0;
    margin: 15px auto 50px;
    border-radius: 5px;
    width: 100%;
    max-width: 680px;

    ${mediaqueries.tablet`
        margin: 10px auto 45px;
      `};

    ${mediaqueries.desktop`
        max-width: 507px;
      `}

    ${mediaqueries.tablet`
        max-width: 486px;
        margin: 0 auto 25px;
      `};

    ${mediaqueries.phablet`
        padding: 0 20px;
      `};
  }

  .Image__Container {
    text-align: center;
  }

  img.Image__With-Shadow {
    box-shadow: 0px 15px 60px rgba(0, 0, 0, 0.15);
  }

  div.Image__Medium {
    position: relative;
    margin: 15px auto 50px;
    width: 100%;
    max-width: ${IMAGE_WIDTHS.large};

    ${mediaqueries.desktop_medium`
        left: -34px;
      `};

    ${mediaqueries.desktop`
        left: -26px;
      `};

    ${mediaqueries.tablet`
        border-radius: 0;
        left: 0;
        margin: 0 auto 25px;
  
        img {
          border-radius: 0;
        }
      `};
  }

  div.Image__Large {
    position: relative;
    left: -68px;
    width: ${IMAGE_WIDTHS.full};
    margin: 25px auto 60px;
    pointer-events: none;

    img {
      border-radius: 0;
    }

    ${mediaqueries.desktop`
        left: -53px;
      `};

    ${mediaqueries.tablet`
        left: 0;
        margin: 0 auto 25px;
      `};
  }
`

/**
 * MDXBody
 * Here we're applying "global" selectors to make sure we maintain an article
 * body type feel. We're also applying all the Prism selecotors and styles within
 * the MDXBody.
 */
const MDXBody = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${HeadingsCSS}
  ${ImageCSS}
`
