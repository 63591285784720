import styled from "@emotion/styled"
import { css } from "@emotion/core"

import mediaqueries from "../../styles/media"

/**
 * Example:
 * <Heading.h1>Lorem Ipsum</Heading.h1>
 */

const commonStyles = p => css`
  font-weight: bold;
  color: ${p.theme.colors.accent};
`

const h1 = styled.h1`
  font-family: ${(p: any) => p.theme.fonts.sansSerif};
  font-size: 36px;
  line-height: 1.15;
  font-style: normal;
  font-weight: 300;
  font-size: 36px;
  line-height: 122.19%;
  letter-spacing: 0.05em;
  color: ${(p: any) => p.theme.colors.white} ${mediaqueries.desktop`
    font-size: 24px;
    line-height: 1.2;
  `};
`

const h2 = styled.h2`
  font-family: ${(p: any) => p.theme.fonts.exo};
  font-size: 36px;
  line-height: 1.15;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 122.19%;
  letter-spacing: 0.05em;
  color: ${(p: any) => p.theme.colors.white};

  ${mediaqueries.desktop`
    font-size: 21px;
  `};

  ${mediaqueries.tablet`
    font-size: 24px;
    line-height: 1.45;
  `};

  ${mediaqueries.phablet`
    font-size: 22px;
  `};
`

const h3 = styled.h3`
  font-size: 24px;
  line-height: 1.45;
  ${commonStyles};

  ${mediaqueries.tablet`
    font-size: 22px;
  `};

  ${mediaqueries.phablet`
    font-size: 20px;
  `};
`

const h4 = styled.h4`
  font-size: 18px;
  line-height: 1.45;
  ${commonStyles};

  ${mediaqueries.phablet`
    font-size: 16px;
  `};
`

const h5 = styled.h5`
  font-size: 18px;
  line-height: 1.45;
  ${commonStyles};

  ${mediaqueries.phablet`
    font-size: 16px;
  `};
`

const h6 = styled.h6`
  font-size: 16px;
  line-height: 1.45;
  ${commonStyles};

  ${mediaqueries.phablet`
    font-size: 14px;
  `};
`

export default {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
}
