import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import mediaqueries from "../../styles/media"

interface Props {
  text: string
  link: string
  type: "external" | "internal"
  orange: boolean
}

const MenuItem: React.FC<Props> = ({ text, link, type, orange }) => {
  if (type === "external") {
    return (
      <Item orange={orange}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      </Item>
    )
  }

  if (type === "internal") {
    return (
      <Item orange={orange}>
        <Link to={link}>{text}</Link>
      </Item>
    )
  }
}

export default MenuItem

const Item = styled.div`
  font-family: ${(p: any) => p.theme.fonts.exo};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  margin: auto 20px;

  color: ${(p: any) => p.theme.colors.primary};

  ${mediaqueries.desktop`
    text-align: center; 
    margin: 20px auto;
`};

  a {
    color: ${(p: any) =>
      p.orange ? p.theme.colors.secondary : p.theme.colors.primary};
    text-decoration: none;
    position: relative;

    &:hover::after {
      content: "";
      width: 100%;
      background: ${(p: any) => p.theme.colors.yellow};
      position: absolute;
      bottom: -5px;
      left: 0;
      height: 1px;
    }
  }
`
