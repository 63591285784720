import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import mediaqueries from "../../styles/media"

interface Props {
  text: string
  link: string
  type: "external" | "internal"
  orange: boolean
}

const DocItem: React.FC<Props> = ({ text, link, type, orange }) => {
  if (type === "external") {
    return (
      <Item orange={orange}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      </Item>
    )
  }

  if (type === "internal") {
    return (
      <Item orange={orange}>
        <Link to={link}>{text}</Link>
      </Item>
    )
  }
}

export default DocItem

const Item = styled.div`
    

  a {
    font-family: ${(p: any) => p.theme.fonts.sansSerif};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 260%;
    /* or 47px */
    letter-spacing: 0.125em;
    color: ${(p: any) => p.theme.colors.primary};

    ${mediaqueries.desktop`
    font-size: 14px;
`};
`
