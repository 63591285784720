import Cell from "./Table.Cell"
import Head from "./Table.Head"
import HeadCell from "./Table.Head.Cell"
import Table from "./Table"

export default {
  Cell,
  Head,
  HeadCell,
  Table,
}
