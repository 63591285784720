import React from "react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Button from "../button"
import Layout from "../layout"

import mediaqueries from "../../styles/media"

const siteQuery = graphql`
  query registerQuery {
    mdx(frontmatter: { gatsbyTitle: { eq: "Register" } }) {
      id
      frontmatter {
        ctaText
        yellowButtonText
        yellowButtonLink
        underlineButtonText
        underlineButtonLink
        image {
          childImageSharp {
            fluid(
              maxWidth: 1000
              quality: 100
              traceSVG: {
                color: "#FFF"
                turnPolicy: TURNPOLICY_MAJORITY
                blackOnWhite: true
              }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`

const Register = () => {
  const data = useStaticQuery(siteQuery)

  const {
    ctaText,
    yellowButtonText,
    yellowButtonLink,
    underlineButtonText,
    underlineButtonLink,
    image,
  } = data.mdx.frontmatter

  return (
    <RegisterHolder>
      <Layout>
        <RegisterCta>{ctaText}</RegisterCta>
        <ButtonsHolder>
          <Button
            text={yellowButtonText}
            external={false}
            link={yellowButtonLink}
            type="yellow"
          />
          <Button
            black
            external={false}
            text={underlineButtonText}
            link={underlineButtonLink}
            type="underline"
          />
        </ButtonsHolder>
        <HeroHolder>
          <Img fluid={image.childImageSharp.fluid} alt={ctaText} />
        </HeroHolder>
      </Layout>
    </RegisterHolder>
  )
}

export default Register

const RegisterHolder = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 750px;
  z-index: 0;
`

const RegisterCta = styled.h2`
font-family: ${(p: any) => p.theme.fonts.sansSerif}
font-style: normal;
font-weight: 600;
font-size: 33px;
line-height: 122.19%;
/* or 40px */
text-align: center;
letter-spacing: 0.05em;
color: ${(p: any) => p.theme.colors.primary};

${mediaqueries.desktop`
font-size: 28px;
`};
`

const ButtonsHolder = styled.div`
  margin-top: 50px;
  min-height: 51px;
  display: flex;
  justify-content: center;
  width: 100%;

  ${mediaqueries.desktop`
  flex-direction: column;
  align-items: center;
`};
`

const HeroHolder = styled.div`
  transform: scaleX(-1);
  position: absolute;
  width: 538px;
  z-index: 1;
  bottom: 26px;
  right: 30px;

  ${mediaqueries.desktop`
  width: 320px;
  bottom: 55px;
  right: 0px;

`};
`
