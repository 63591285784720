import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import MDX from "../mdx"
import Layout from "../layout"
import MenuItem from "./MenuItem"
import DocItem from "./DocumentsItem"

import mediaqueries from "../../styles/media"

interface Props {
  marginTop?: number
}

const siteQuery = graphql`
  query footerQuery {
    mdx(frontmatter: { gatsbyTitle: { eq: "Footer" } }) {
      id
      frontmatter {
        title
        navigation {
          name
          link
          type
          orange
        }
        documents {
          name
          link
          type
        }
        email
      }
      body
    }
  }
`

const Footer: React.FC<Props> = ({ marginTop = "-100px" }) => {
  const data = useStaticQuery(siteQuery)

  const { title, navigation, documents, email } = data.mdx.frontmatter
  const { body } = data.mdx
  return (
    <FooterHolder marginTop={marginTop}>
      <Layout padding="0 50px 100px 50px" footer>
        <ShadowBox padding="50px">
          <LeftHolder>
            <MDX content={body} />
            <EmailLink href={`mailto:${email}`}>{email}</EmailLink>
          </LeftHolder>
          <RightHolder>
            {documents.map((item: any) => (
              <DocItem
                key={item.name}
                text={item.name}
                type={item.type}
                link={item.link}
                orange={item.orange}
              />
            ))}
          </RightHolder>
        </ShadowBox>
        <div style={{ height: "50px" }} />
        <ShadowBox padding="10xp">
          <FooterNavigation>
            {navigation.map((item: any) => (
              <MenuItem
                key={item.name}
                text={item.name}
                type={item.type}
                link={item.link}
                orange={item.orange}
              />
            ))}
          </FooterNavigation>
        </ShadowBox>
      </Layout>
      <Desginedby>
        Designed by{" "}
        <a href="https://spaceout.pl" target="_blank" rel="noopener noreferrer">
          spaceout.pl
        </a>
      </Desginedby>
    </FooterHolder>
  )
}

export default Footer

const Desginedby = styled.div`
  margin-top: 100px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 10px;
  color: ${(p: any) => p.theme.colors.topBarBackground};

  & a {
    color: ${(p: any) => p.theme.colors.topBarBackground};
  }
`

const FooterHolder = styled.footer`
  position: relative;
  background: ${(p: any) => p.theme.colors.background};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: ${(p: any) => p.marginTop};
  position: relative;
  ${mediaqueries.desktop`
  margin-bottom: 50px;
`};
`

const ShadowBox = styled.div`
  filter: ${(p: any) => p.theme.shadow.box};
  display: flex;
  justify-content: space-around;
  align-items: "center";
  z-index: 3;
  padding: ${(p: any) => p.padding};
  border-radius: ${(p: any) => p.theme.radius.normal};
  background: ${(p: any) => p.theme.colors.white};
  ${mediaqueries.desktop`
  flex-direction: column;
`};
`
const LeftHolder = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  padding-right: 50px;

  & strong {
    font-size: 17px !important;
  }

  ${mediaqueries.desktop`
   width: 100%;
   padding: 10px;
`}
`

const RightHolder = styled.div`
  position: relative;
  width: 60%;
  display: flex;
  padding-left: 50px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  ${mediaqueries.desktop`
   width: 100%;
   margin-top: 10px;
   padding: 20px 10px;
`};

  &:after {
    content: " ";
    position: absolute;
    height: 80%;
    width: 1px;
    left: 0;
    top: 10%;
    background: ${(p: any) => p.theme.colors.yellow};

    ${mediaqueries.desktop`
    height: 1px;
    width: 100%;
    top: 0;
    left: 0;
`};
  }
`

const EmailLink = styled.a`
  font-family: ${(p: any) => p.theme.fonts.sansSerif};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 197.18%;
  /* or 28px */
  letter-spacing: 0.015em;
  color: ${(p: any) => p.theme.colors.secondary};
  margin-top: 25px;
  align-self: flex-start;
`

const FooterNavigation = styled.nav`
  display: flex;
  width: 100%;
  max-width: 700px;
  padding: 20px;
  justify-content: space-around;

  ${mediaqueries.desktop`
  flex-direction: column;
  min-height: 400px;
`};
`
