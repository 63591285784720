import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import { css } from "@emotion/core"

interface Props {
  text: string
  link: string
  type: "external" | "internal"
  active: boolean
}

const MenuItem: React.FC<Props> = ({ text, link, type, active }) => {
  if (type === "external") {
    return (
      <Item active={active}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      </Item>
    )
  }

  if (type === "internal") {
    return (
      <Item active={active}>
        <Link to={link}>{text}</Link>
      </Item>
    )
  }
}

export default MenuItem

const Item = styled.li`
  font-family: ${(p: any) => p.theme.fonts.exo};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  margin: auto 20px;

  color: ${(p: any) => p.theme.colors.primary};

  a {
    color: ${(p: any) => p.theme.colors.primary};
    text-decoration: none;
    position: relative;

    &:after {
      content: "";
      width: 100%;
      background: ${(p: any) => p.theme.colors.secondary};
      position: absolute;
      bottom: -5px;
      left: 0;
      height: ${(p: any) => (p.active ? "1px" : "0px")};
    }

    &:hover::after {
      content: "";
      width: 100%;
      background: ${(p: any) => p.theme.colors.yellow};
      position: absolute;
      bottom: -5px;
      left: 0;
      height: 1px;
    }
  }
`
