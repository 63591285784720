import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import mediaqueries from "../../styles/media"

interface Props {
  text: string
  link: string
  external: boolean
  black?: boolean
  type: "yellow" | "underline"
}

const Button: React.FC<Props> = ({ text, link, external, type, black }) => {
  if (type === "yellow") {
    if (external) {
      return (
        <YellowButton>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        </YellowButton>
      )
    }

    if (!external) {
      return (
        <YellowButton>
          <Link to={link}>{text}</Link>
        </YellowButton>
      )
    }
  }

  if (type === "underline") {
    if (external) {
      return (
        <UnderlineButton black={black}>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        </UnderlineButton>
      )
    }

    if (!external) {
      return (
        <UnderlineButton black={black}>
          <Link to={link}>{text}</Link>
        </UnderlineButton>
      )
    }
  }
}

export default Button

const commonStyles = p => css`
  position: relative;
  display: flex;
  justifycontent: center;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 122.19%;
  /* or 22px */
  letter-spacing: 0.05em;
  border-radius: ${p.theme.radius.normal};
  border: 0;
  z-index: 99999;
  margin-right: 20px;
  transition: ${p.theme.transition.button};

  a {
    border: 0;
    margin: auto;
    font-family: ${p.theme.fonts.sansSerif};
    text-decoration: none;
    padding: 5px 10px;
    transition: ${p.theme.transition.button};
  }

  ${mediaqueries.desktop`
        font-size: 14px;
        min-height: 35px;
        max-width: 150px;
        margin: 20px 0;    
    `};
`

const YellowButton = styled.button`
  background: ${p => p.theme.colors.yellow};

  ${commonStyles}

  a {
    color: ${p => p.theme.colors.primary};
  }

  &:hover {
    background: transparent;
    border: 1px solid ${p => p.theme.colors.yellow};
    color: ${p => p.theme.colors.white};

    a {
      color: ${p => p.theme.colors.yellow};
    }
  }
`
const UnderlineButton = styled.button`
  background: transparent;
  width: 166px;
  height: 48px;

  a {
    color: ${(p: any) =>
      p.black ? p.theme.colors.primary : p.theme.colors.white};
  }
  ${commonStyles}

  &:after {
    content: "";
    width: 90%;
    background: ${p => p.theme.colors.yellow};
    position: absolute;
    bottom: 0;
    left: 10px;
    height: 1px;
  }

  &:hover {
    background: ${p => p.theme.colors.yellow};

    a {
      color: ${p => p.theme.colors.primary};
    }
  }
`
