import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import mediaqueries from "../../styles/media"
import MenuItem from "./MenuItem"

interface Props {
  location: any
  isOpen: boolean
  setOpen: Function
  theme?: any
}

interface Item {
  name: string
  type: "internal" | "external"
  link: string
}

const siteQuery = graphql`
  query navMobileQuery {
    mdx(frontmatter: { title: { eq: "Navigation" } }) {
      id
      frontmatter {
        leftMenu {
          name
          link
          type
        }
        rightMenu {
          name
          link
          type
        }
        logo {
          id
          publicURL
        }
        logoAlt {
          id
          publicURL
        }
        hamburger {
          id
          publicURL
        }
        hamburgerOpen {
          id
          publicURL
        }
        close {
          id
          publicURL
        }
      }
    }
  }
`

const checkActive = (link: string, pathname: string) => {
  if (pathname === "/" && link === "/#Produkt") {
    return true
  }

  if (pathname === link) {
    return true
  }

  return false
}

const Navigation: React.FC<Props> = ({ location, isOpen, setOpen }) => {
  const data = useStaticQuery(siteQuery)
  const {
    leftMenu,
    rightMenu,
    logo,
    logoAlt,
    hamburger,
    hamburgerOpen,
    close,
  } = data.mdx.frontmatter

  return (
    <NavigationHolder>
      <Hamburger onClick={() => setOpen(!isOpen)}>
        <img src={hamburger.publicURL} alt="menu" />
      </Hamburger>
      <LogoAlt>
        <Link to="/">
          <img src={logoAlt.publicURL} alt="OpalCBox logo" />
        </Link>
      </LogoAlt>
      <Menu isOpen={isOpen}>
        <OpenMenuButtonsHolder>
          <img
            onClick={() => setOpen(!isOpen)}
            src={hamburgerOpen.publicURL}
            alt="zamknij menu"
          />
          <img
            onClick={() => setOpen(!isOpen)}
            src={close.publicURL}
            alt="zamknij menu"
          />
        </OpenMenuButtonsHolder>
        <LeftMenu>
          {leftMenu.map((item: Item) => (
            <MenuItem
              key={item.name}
              text={item.name}
              link={item.link}
              type={item.type}
              active={checkActive(item.link, location.pathname)}
            />
          ))}
        </LeftMenu>
        <RightMenu>
          {rightMenu.map((item: Item) => (
            <MenuItem
              key={item.name}
              text={item.name}
              link={item.link}
              type={item.type}
              active={checkActive(item.link, location.pathname)}
            />
          ))}
        </RightMenu>
        <Link to="/">
          <MobileOpenLogo
            width="105px"
            src={logo.publicURL}
            alt="OpalCBoc logo"
          />
        </Link>
      </Menu>
    </NavigationHolder>
  )
}

const NavigationHolder = styled.nav`
  display: none;

  ${mediaqueries.desktop`
  width: 100%;
    height: 55px;
    padding: 10px 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 20px auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    z-index: 9999999999999;
    position: fixed;
`};
`

const OpenMenuButtonsHolder = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const Hamburger = styled.div`
  filter: ${(p: any) => p.theme.shadow.box};
  margin: auto;
  margin-left: -10px;
`
const LogoAlt = styled.div`
  display: block;
`

const Menu = styled.div`
  display: flex;
  width: 100%;
  height: 110%;
  position: fixed;
  flex-direction: column;
  transition: 0.5s ease-in;
  z-index: 99999999999999;
  background: ${(p: any) => p.theme.colors.background};
  transform: ${(p: any) =>
    p.isOpen ? "translate(-30px,-62px)" : "translate(-120vw, -62px)"};
  padding: 30px;
  margin: 25px auto;
`

const LeftMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  margin: 20px auto;
  list-style: none;
  min-height: 250px;
`

const RightMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%%;
  list-style: none;
  min-height: 120px;

  & a {
    color: ${(p: any) => p.theme.colors.secondary};
  }
`
const MobileOpenLogo = styled.img`
  position: absolute;
  right: 0px;
  bottom: 10vh;
`

export default Navigation
