import React from "react"

import styled from "@emotion/styled"
import { css } from "@emotion/core"

import mediaqueries from "../../styles/media"

interface Props {
  padding?: string
  children: React.ReactNode
  footer?: boolean
}

const LayoutWrapper: React.FC<Props> = ({
  padding = "50px 20px",
  children,
  footer,
}) => {
  return (
    <Layout padding={padding} footer={footer}>
      {children}
    </Layout>
  )
}

const Layout = styled.div`
  position: relative;
  max-width: 1440px;
  width: 100%;
  padding: ${(p: any) => p.padding};
  height: 100%;

  ${mediaqueries.desktop`
  padding:  0px 15px;
`};
`

export default LayoutWrapper
